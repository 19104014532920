import {
  createStore as _createStore,
  compose as reduxCompose,
  applyMiddleware
} from 'redux'
import { resolvableMiddleware } from '@touchtribe/redux-helpers'
import createSagaMiddleware from 'redux-saga'
import invariant from 'invariant'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import createReducer from './reducers'
import rootSaga from './sagas'
import isClient from 'utils/is-client'

const isDevelopment = process.env.NODE_ENV === 'development'
const GLOBAL_STORE_KEY = isDevelopment ? 'redux_store' : Symbol('redux_store')

export default function createStore ({
  initialState,
  runSagas = false
}) {
  if (isClient && window[GLOBAL_STORE_KEY]) {
    return window[GLOBAL_STORE_KEY]
  }

  const store = createReduxStore({
    initialState: initialState || {},
    reducer: createReducer(),
    saga: (runSagas || isClient) ? rootSaga : undefined,
    compose: composeWithDevTools
  })

  if (isClient) {
    window[GLOBAL_STORE_KEY] = store
  }

  return store
}

export function createReduxStore ({
  initialState = {},
  enhancers = [],
  middlewares = [],
  reducer,
  saga,
  sagaMiddlewareOptions = {},
  compose = reduxCompose
}) {
  invariant(typeof initialState === 'object', '"initialState" must be an object')
  invariant(typeof reducer === 'function', '"reducer" must be a function')
  invariant(saga === undefined || typeof saga === 'function', '"saga" must be a function')
  invariant(typeof sagaMiddlewareOptions === 'object', '"sagaMiddlewareOptions" must be an object')
  invariant(Array.isArray(enhancers), '"enhancers" must be an array')
  invariant(Array.isArray(middlewares), '"middlewares" must be an array')
  invariant(typeof compose === 'function', '"compose" must be a function')
  const sagaMiddleware = createSagaMiddleware(sagaMiddlewareOptions)

  enhancers = [
    applyMiddleware(
      ...middlewares,
      resolvableMiddleware,
      sagaMiddleware
    ),
    ...enhancers
  ]
  const store = _createStore(
    reducer,
    initialState,
    compose(...enhancers)
  )

  const sagaTask = typeof saga === 'function' ? sagaMiddleware.run(saga) : undefined

  return {
    store,
    sagaTask
  }
}
