import { Link } from '@features/shared/components/link';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import { IMenuItem } from '../../contentful/types/IMenuItem';
import styles from './NavigationListSecondary.module.scss';

interface NavigationListSecondaryProps {
  items?: IMenuItem[];
}

export function NavigationListSecondary({
  items = [],
}: NavigationListSecondaryProps) {
  const pushEvent = (item: IMenuItem) => {
    pushGA4Event(GA4_EVENTS.menu, {
      interaction_type: 'click',
      link_test: item.link?.label,
      link_url: item.link?.href || `/${item.link?.slug}`,
    });
  };

  return (
    <ul>
      {items.map((item) => {
        const href = item.link?.href;

        if (!href) {
          return null;
        }

        return (
          <li key={item.link?.label} className={styles.item}>
            <Link
              href={href}
              onClick={() => pushEvent(item)}
              isHtmlTag={href.startsWith('/funnel')}
              className={styles.link}
            >
              {item.link?.label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
}
