import { createResolvableActionsDomain } from '@touchtribe/redux-helpers'
import { DOMAIN } from './constants'

const createResolvableActions = createResolvableActionsDomain(DOMAIN)

export const [
  fetchMenus,
  fetchMenusSuccess,
  fetchMenusFail
] = createResolvableActions('fetchMenus', {
  init: (lang, preview) => ({ lang, preview }),
  resolve: (lang, data, preview) => ({
    lang, data, preview
  }),
  reject: (statusCode, message) => ({ statusCode, message })
})

export const [
  fetchAllPageUrls,
  fetchAllPageUrlsSuccess,
  fetchAllPageUrlsFail
] = createResolvableActions('fetchAllPageUrls', {
  init: () => ({}),
  resolve: (pages) => ({ pages }),
  reject: (statusCode, message) => ({ statusCode, message })
})

export const [
  fetchAllClubPageUrls,
  fetchAllClubPageUrlsSuccess,
  fetchAllClubPageUrlsFail
] = createResolvableActions('fetchAllClubPageUrls', {
  init: () => ({}),
  resolve: (clubPages) => ({ clubPages }),
  reject: (statusCode, message) => ({ statusCode, message })
})

export const [
  fetchCheckout,
  fetchCheckoutSuccess,
  fetchCheckoutFail
] = createResolvableActions('fetchCheckout', {
  init: (lang, isAlternative, promoQueryParameter, preview) => ({
    lang, isAlternative, promoQueryParameter, preview
  }),
  resolve: (lang, isAlternative, promoQueryParameter, data, preview) => ({
    lang, data, preview
  }),
  reject: (statusCode, message) => ({ statusCode, message })
})
