import { MappedImage } from '@features/pg-funnel/services/contentful/types'
import { IDealSectionFields } from '@features/pg-funnel/services/contentful/types/generated/contentful'
import { IDealSectionMapped } from '@features/shared/types/IDealSectionMapped'
import { mapLink } from '@features/shared/contentful/mappers/mapLink'
import mapImage from '@services/contentful/utils/map-image'

export default function mapDealSection (data: IDealSectionFields): IDealSectionMapped | null {
  if (!data) return null

  return {
    title: data.title,
    description: data.description,
    image: data.image
      ? {
        ...mapImage(data.image, [ 600, 289, 381, 476 ]),
        title: data.image.fields.title,
        description: data.image.fields.description
      } as MappedImage
      : null,
    primaryLink: data.primaryLink && data.primaryLinkLabel && !data.isPrimaryLinkCheckoutWithClub
      ? mapLink(data.primaryLink, data.primaryLinkLabel)
      : null,
    primaryLinkLabel: data.isPrimaryLinkCheckoutWithClub && data.primaryLinkLabel ? data.primaryLinkLabel : '',
    isPrimaryLinkCheckoutWithClub: data.isPrimaryLinkCheckoutWithClub,
    secondaryLink: data.secondaryLink && data.secondaryLinkLabel
      ? mapLink(data.secondaryLink, data.secondaryLinkLabel)
      : null,
    isImageRightPosition: data.isImageRightPosition,
    backgroundColor: data.backgroundColor || null
  }
}
