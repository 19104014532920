import { Contract, VoucherData } from '@features/pg-funnel/services/perfect-gym/types'

export interface Voucher extends Contract {
    summary: {
        voucher: {
            code: string,
            valid: boolean,
            description: string
            amount: number
            discountId: number
            sinceInterval: number
            untilInterval: number
        },
        joiningFee: {
            amount: number
            withoutVoucher: number
            regularAmount: number
        },
        membership: {
            total: number
            withoutVoucher: number
            regularAmount: number
        }
    }
}

export const mapVoucher = (voucherData: VoucherData): Voucher => {
  return {
    ...voucherData,
    summary: {
      voucher: {
        ...voucherData.summary.voucher
      },
      joiningFee: {
        amount: voucherData.summary.joining_fee.amount,
        withoutVoucher: voucherData.summary.joining_fee.without_voucher,
        regularAmount: voucherData.summary.joining_fee.regular_amount
      },
      membership: {
        total: voucherData.summary.membership.total,
        withoutVoucher: voucherData.summary.membership.without_voucher,
        regularAmount: voucherData.summary.membership.regular_amount
      }
    }
  }
}
