import { RefObject, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'src/utils/hooks/use-on-click-outside';

interface UseAnimateHeightReturn {
  containerRef: RefObject<HTMLDivElement> | null;
  parentRef: RefObject<HTMLDivElement> | null;
  isOpen: boolean;
  toggleOpen: () => void;
  setIsOpen: (isOpen: boolean) => void;
}

interface IUseAnimateHeight {
  closeOnOutsideClick?: boolean;
}

export function useAnimateHeight({
  closeOnOutsideClick = false,
}: IUseAnimateHeight = {}): UseAnimateHeightReturn {
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
    if (
      closeOnOutsideClick &&
      parentRef.current &&
      !parentRef.current.contains(event.target as HTMLElement)
    ) {
      setIsOpen(false);
    }
  };

  useOnClickOutside(parentRef, handleOutsideClick);

  useEffect(() => {
    if (containerRef.current && isOpen) {
      containerRef.current.setAttribute(
        'style',
        `height: ${containerRef.current.scrollHeight}px`,
      );
    }

    if (containerRef.current && !isOpen) {
      containerRef.current.setAttribute('style', 'height: 0');
    }
  }, [isOpen]);

  return {
    containerRef,
    toggleOpen,
    isOpen,
    parentRef,
    setIsOpen,
  };
}
