import {
  takeLatest,
  takeEvery,
  put,
  call,
  select
} from 'redux-saga/effects'
import * as Actions from './actions'
import * as Selectors from './selectors'
import { getContractsByClubId, getContractByPaymentPlanId } from '../../services/perfect-gym'

export default function * contractsSaga () {
  yield takeEvery(Actions.fetchContractByPaymentPlanId, onFetchContractByPaymentPlanId)
  yield takeLatest(Actions.fetchContractsByClubId, onFetchContractsByClubId)
}

function * onFetchContractsByClubId ({
  resolve,
  reject,
  clubId,
  funnelSlug,
  paymentPlansById
}) {
  try {
    const contractsById = yield call(getContractsByClubId, funnelSlug, clubId, paymentPlansById)
    yield put(resolve(funnelSlug, contractsById))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}

function * onFetchContractByPaymentPlanId ({
  resolve,
  reject,
  funnelSlug,
  clubId,
  paymentPlan
}) {
  const contract = yield select(Selectors.getContractByPaymentPlanId(funnelSlug, paymentPlan.id))
  if (contract) {
    yield put(resolve(funnelSlug, contract))
  }

  try {
    const contract = yield call(getContractByPaymentPlanId, funnelSlug, clubId, paymentPlan)
    if (contract) {
      yield put(resolve(funnelSlug, contract))
    }
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}
