import { PGClient } from './client';
import { ROUTES } from './routes';
import {
  Contract,
  ContractWithPaymentPlanId,
  Discount,
  DiscountDefinition,
  PaymentPlan,
  SignUpData,
} from '@features/pg-funnel/services/perfect-gym/types';
import { ElectrolyteClient } from '@services/electrolyte/client'; // TODO: Check import
import { ROUTES as PAYMENT_ROUTES } from '@services/electrolyte/routes';
import {
  ContractsByPaymentPlanId,
  EnrichedPaymentPlansById,
} from '@features/pg-funnel/types';
import { getContract } from '@features/pg-funnel/services/proxy';
import { getContractDiscountDefinitions } from '@features/pg-funnel/services/perfect-gym/utils';
import { EnrichedPaymentPlan } from '@features/pg-funnel/services/perfect-gym/mappers/map-payment-plans';
import axios from 'axios';

export const getDiscountById = async (id: number) => {
  const { data } = await PGClient.get(`${ROUTES.DISCOUNT}(${id})`, {
    params: {
      $expand: 'clubs,paymentPlans',
    },
  });

  return data;
};

export interface GetDiscountsProps {
  discountIds?: number[];
  withClubs?: boolean;
  isActive?: boolean;
}

export const getDiscounts = async ({
  discountIds,
  withClubs,
  isActive = true,
}: GetDiscountsProps): Promise<Discount[]> => {
  const response = await PGClient.get(ROUTES.DISCOUNT, {
    // todo: tidy up
    params: {
      $expand: `paymentPlans${withClubs ? ',clubs' : ''}`,
      $filter: `${isActive ? 'isActive' : ''}${
        discountIds
          ? `${isActive ? ' and' : ''} Id in (${discountIds.join(',')})`
          : ''
      }`,
    },
  });

  return response.data.value;
};

// @TODO: typing
export const getPaymentPlans = async (
  ids: number[],
): Promise<PaymentPlan[]> => {
  const response = await PGClient.get(ROUTES.PAYMENT_PLANS, {
    params: {
      $expand: 'availableInClubs',
      $filter: `Id in (${ids.join(',')})`,
    },
  });

  return response.data.value;
};

export interface ContractParams {
  clubId: number;
  paymentPlanId: number;
  startDate?: string | Date;
  contractDiscountsData?: DiscountDefinition[];
  discountIds?: number[];
  voucherCode?: string;
}

export const getContractBySimulate = async ({
  clubId,
  paymentPlanId,
  startDate,
  contractDiscountsData,
}: ContractParams): Promise<Contract> => {
  const { data } = await PGClient.post(ROUTES.SIMULATE_NEW_CONTRACT, {
    clubId,
    paymentPlanId,
    startDate,
    contractDiscountsData,
  });

  return data;
};

export const postSignUp = async (signUpData: SignUpData) => {
  try {
    const response = await ElectrolyteClient.post(
      PAYMENT_ROUTES.SIGN_UP,
      JSON.stringify(signUpData),
    );

    return {
      data: {
        ...response.data,
        paymentlink: response.data.payment_url,
      },
    };
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return {
        ...e.response?.data,
      };
    }
    return {
      status: 'failed',
    };
  }
};

export const getContractsByClubId = async (
  funnelSlug: string,
  clubId: string,
  paymentPlansById: EnrichedPaymentPlansById,
) => {
  if (clubId) {
    const filteredPaymentPlans = Object.values(paymentPlansById).filter(
      (plan) => plan.attributes.clubIds?.includes(clubId),
    );

    // then per payment plan, execute a simulateNewContract, so we can get the prices
    const contracts: Array<ContractWithPaymentPlanId | undefined> =
      await Promise.all(
        filteredPaymentPlans.map(async (plan) => {
          const contractDiscountsData = getContractDiscountDefinitions({
            paymentPlan: plan,
            funnelSlug,
          });

          // added try/catch, so we can grab all contracts that are returned and filter out those that weren't. Before it was all or nothing, as the it would throw if one of the promises didn't resolve.
          try {
            const { data } = await getContract({
              clubId: Number(clubId),
              paymentPlanId: plan.id,
              contractDiscountsData,
            });
            // add the paymentPlanId so we can tell them apart later
            return { ...data, id: plan.id };
          } catch (e) {
            return undefined;
          }
        }),
      );

    // since we can't guarantee all promises return a contract, we filter out those that did not
    const returnedContracts: ContractWithPaymentPlanId[] = contracts.filter(
      Boolean,
    ) as ContractWithPaymentPlanId[];

    // create an object with contracts stored by paymentPlanId, so we can save that in our store
    // ALTERNATIVE: is to map the contracts into the paymentPlans. My issue with that approach is that it becomes unclear when a paymentPlan includes prices and when it doesn't.
    const contractsByPaymentPlanId: ContractsByPaymentPlanId =
      returnedContracts.reduce((acc, contract) => {
        return {
          ...acc,
          [contract.id]: {
            ...contract,
          },
        };
      }, {});

    return contractsByPaymentPlanId;
  }
};

export const getContractByPaymentPlanId = async (
  funnelSlug: string,
  clubId: string,
  paymentPlan: EnrichedPaymentPlan,
) => {
  if (paymentPlan) {
    const contractDiscountsData = getContractDiscountDefinitions({
      paymentPlan,
      funnelSlug,
    });
    const { data } = await getContract({
      clubId: Number(clubId),
      paymentPlanId: paymentPlan.id,
      contractDiscountsData,
    });

    // add the paymentPlanId so we can tell them apart later
    return { ...data, id: paymentPlan.id };
  }
};

export const getVoucherCodeData = async (contractData: ContractParams) => {
  const { data } = await ElectrolyteClient.post(
    PAYMENT_ROUTES.CONTRACT_DETAILS,
    JSON.stringify(contractData),
  );

  return data;
};

interface IAccessCodeResponseError {
  response: { status: number };
}

export const getAccessCodeStatus = async (id: string) => {
  try {
    const response = await ElectrolyteClient.get(
      `${PAYMENT_ROUTES.VOUCHER_CODES}/${id}`,
    );

    // Normal response: 200
    return response.status;
  } catch (error) {
    // Error response: 403, 404 or other, return the status if it exists or 404 by default
    return (error as IAccessCodeResponseError)?.response?.status ?? 404;
  }
};
