import { FUNNEL_STEP_SLUGS } from '@features/pg-funnel/utils/constants';
import { GA4_EVENTS } from './constants';

export const pushGA4Event = (event: string, params?: object) => {
  if (!event) return;
  const object = {
    event,
    ...params,
  };

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(object);
};

export const getTrackEventByStep = (step: string) => {
  switch (step) {
    case FUNNEL_STEP_SLUGS.welkom:
      return GA4_EVENTS.clickCode;

    case FUNNEL_STEP_SLUGS.selectMembership:
      return GA4_EVENTS.clickMembership;

    case FUNNEL_STEP_SLUGS.personalInfo:
      return GA4_EVENTS.clickPersonalInfo;

    case FUNNEL_STEP_SLUGS.summary:
      return GA4_EVENTS.clickStartVerification;

    case FUNNEL_STEP_SLUGS.addOns:
      return GA4_EVENTS.clickExtras;

    default:
      return '';
  }
};
