import { JoinButton } from '@features/shared/components/join-button';
import { TemporaryOffer } from '@features/shared/components/temporary-offer';
import { useSheet } from '@features/shared/hooks/use-sheet';
import classNames from 'classnames';
import theme from 'config/theme';
import { useIntl } from 'domains/i18n';
import Icons from 'features/shared/components/icons';
import { NavigationList } from 'features/shared/components/navigation-list';
import { NavigationListSecondary } from 'features/shared/components/navigation-list-secondary';
import { Overlay } from 'features/shared/components/overlay';
import { useMemo, useRef } from 'react';
import { IMappedMenus } from 'src/features/layout/menu/types/IMappedMenus';
import { Button } from 'src/features/shared/components/button';
import { Image } from 'src/features/shared/components/image';
import { Container } from 'src/features/shared/components/layout';
import { LoginLink } from 'src/features/shared/components/login-link';
import { ITemporaryOffer } from 'src/features/shared/contentful/types/ITemporaryOffer';
import { ButtonVariant } from 'src/features/shared/types/enums/ButtonVariant';
import styles from './index.module.scss';

interface HeaderProps {
  menus: IMappedMenus;
  temporaryOffer?: ITemporaryOffer;
  isAlternative?: boolean;
  clubId?: number;
}

export function Header({
  menus,
  temporaryOffer,
  isAlternative,
  clubId,
}: HeaderProps) {
  const { headerPrimaryMenu, headerSecondaryMenu } = menus;
  const { formatMessage } = useIntl();
  const sheet = useSheet();
  const label = useMemo(() => formatMessage('more'), [formatMessage]);

  const Icon = sheet.isOpen || sheet.isOpening ? Icons.Cross : Icons.Menu;

  const isBelowHeader = temporaryOffer?.positionBelowHeader;

  const headerWrapperRef = useRef(null);

  return (
    <>
      <Overlay sheet={sheet} className={styles.overlay} />

      <header
        className={classNames(styles.container, {
          [styles.active]: sheet.isOpen || sheet.isOpening,
        })}
      >
        <nav
          className={classNames(styles['floating-navigation'], {
            [styles.active]:
              (sheet.isOpen || sheet.isOpening) && !sheet.isClosing,
          })}
          onClick={sheet.toggle}
        >
          <div className={styles.primary}>
            <NavigationList items={headerPrimaryMenu.items} />
          </div>
          <div className={styles.secondary}>
            <NavigationListSecondary items={headerSecondaryMenu.items} />
          </div>
        </nav>
        {!isBelowHeader && temporaryOffer && (
          <TemporaryOffer {...temporaryOffer} />
        )}
        <section className={styles.header}>
          <Container>
            <section
              className={classNames(styles.wrapper, {
                [styles.alternative]: isAlternative,
              })}
              ref={headerWrapperRef}
            >
              <a href="/" className={styles.link}>
                <Image
                  className={classNames(styles.logo, styles.mobile)}
                  image={{
                    src: theme.logo.mobile,
                    alt: `${theme.name} logo`,
                  }}
                  hasLazyLoading={false}
                />

                <Image
                  className={classNames(styles.logo, styles.desktop)}
                  image={{
                    src: theme.logo.desktop,
                    alt: `${theme.name} logo`,
                  }}
                  hasLazyLoading={false}
                />
              </a>
              <section className={styles.body}>
                <nav className={styles.navigation}>
                  <NavigationList
                    items={headerPrimaryMenu.items}
                    isHorizontal
                  />

                  <Button
                    icon={<Icons.CaretDown />}
                    variant={ButtonVariant.Link}
                    onClick={sheet.toggle}
                    className={classNames(styles.more, {
                      [styles.active]: sheet.isOpen || sheet.isOpening,
                    })}
                  >
                    {label}
                  </Button>
                </nav>
              </section>

              <section className={styles.rightNavContainer}>
                {theme.login && (
                  <div className={styles.login}>
                    <LoginLink />
                  </div>
                )}
                <div className={styles.join}>
                  <JoinButton clubId={clubId} />
                </div>
                <button
                  className={styles.toggle}
                  aria-label="navigation"
                  onClick={sheet.toggle}
                >
                  <Icon className={styles.icon} />
                </button>
              </section>
            </section>
          </Container>
        </section>
      </header>
      {isBelowHeader && temporaryOffer && (
        <TemporaryOffer {...temporaryOffer} />
      )}
    </>
  );
}
