import { createSelector } from 'reselect'
import reducer from './reducers'
import getPropSelector from 'utils/redux/helpers/get-prop-selector'

export const getState = state => state[reducer] || {}

export const getClubs = createSelector(
  getState,
  (state) => state.clubs,
  (clubs) => clubs.items
)

export const getId = getPropSelector('id')

export const getClubById = createSelector(
  getClubs,
  getId,
  (clubs, id) => clubs[id]
)
