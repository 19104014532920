import mapUsps from 'services/contentful/utils/map-usps'

export default function mapUspsSection (data) {
  if (!data) return
  return {
    title: data.title,
    theme: data.theme,
    items: mapUsps(data.items)
  }
}
