const CONTENT_TYPES = {
  CHECKOUT_AGREEMENT: 'checkoutAgreement',
  CHECKOUT_AGREEMENTS: 'checkoutAgreements',
  CHECKOUT_CONTRACT_PERIOD: 'checkoutContractPeriod',
  CHECKOUT_FRIENDS_CODE: 'checkoutFriendsCode',
  CHECKOUT_MEMBERSHIP: 'checkoutMembership',
  CHECKOUT_PAYMENT_PERIODS: 'checkoutPaymentPeriods',
  CHECKOUT_PROMO: 'checkoutPromo',
  CHECKOUT_STEP: 'checkoutStep',
  CHECKOUT_USP: 'checkoutUsp',
  CHECKOUT_USPS: 'checkoutUsps',
  CHECKOUT: 'checkout',
  CONFIRMATION_DETAILS_SECTION: 'confirmationDetails',
  CLUB_PAGE: 'clubPage',
  CONTENT_PAGE: 'contentPage',
  COMPARISON_TABLE: 'comparisonTable',
  DEAL_SECTION: 'dealSection',
  FACILITIES_SECTION: 'facilitiesSection',
  FAQ: 'faq',
  FUNNEL: 'funnel',
  FUNNEL_ATTRIBUTE: 'funnelAttribute',
  FUNNEL_ATTRIBUTE_CHOICE: 'funnelAttributeChoice',
  FUNNEL_ATTRIBUTE_OPTION: 'funnelAttributeOption',
  FUNNEL_ADD_ON_OPTION: 'funnelAddOnOption',
  FUNNEL_ADD_ONS_SECTION: 'funnelAddOnsSection',
  FUNNEL_CLUB_SEARCH: 'funnelClubSearch',
  FUNNEL_CLIENT_INFO_FORM: 'funnelClientInfoForm',
  FUNNEL_STEP: 'funnelStep',
  FUNNEL_SUMMARY: 'funnelSummary',
  IMAGE_CAROUSEL_SECTION: 'imageCarouselSection',
  IMAGE_CAROUSEL_ITEM: 'imageCarouselItem',
  IMAGE_SECTION: 'imageSection',
  LINK: 'link',
  MAP_SECTION: 'mapSection',
  MEMBERSHIP: 'membership',
  MENU: 'menu',
  MENU_ITEM: 'menuItem',
  PAGE_HEADER: 'pageHeader',
  VIDEO_PAGE_HEADER: 'videoPageHeader',
  PAYMENT_PAGES: 'paymentPages',
  REDIRECT: 'redirect',
  SECTION: 'section',
  SPECIALIST: 'specialist',
  USP_ITEM: 'uspItem',
  USP_SECTION: 'uspSection',
  TEMPORARY_OFFER: 'temporaryOffer',
  LINK_LIST: 'linkList',
  IMAGE_SLIDER: 'imageSliderSection',
  IMAGE_SLIDER_ITEM: 'imageSliderItem',
  CLUB_OFFERS: 'clubOffers',
  CLUB_ATTRIBUTE: 'clubAttribute',
  GENERIC_CONTENT_SECTION: 'genericContentSection',
  QUOTE_SECTION: 'quoteSection',
  MEMBERSHIP_SECTION: 'membershipSection',
  FOOTER: 'footer',
  FOOTER_COLUMN: 'footerColumn',
  GROUP_LESSONS: 'groupLessons',
  PROMO_SECTION: 'promoSection',
  OPENING_HOURS: 'openingHours',
  NUMBERED_LIST_SECTION: 'numberedListSection',
  VIDEO_SECTION: 'videoSection',
  EXPANDABLE_TEXT_SECTION: 'expandableTextSection',
  JOB_VACANCIES_SECTION: 'jobVacanciesSection',
  // old news section and item renamed
  CARD_SECTION: 'newsSection',
  CARD_ITEM: 'newsItem',
  CHECKOUT: 'checkout',
  CARD_SLIDER: 'cardSlider',
};

const CLUB_ATTRIBUTE_ICONS = {
  NONE: 'no_icon',
  ACCESSIBILITY: 'accessibility',
  AIRCON: 'aircon',
  CARDIO: 'cardio',
  CIRCUIT: 'circuit',
  CITY_CENTER: 'city_center',
  COACHING: 'coaching',
  COFFEE: 'coffee',
  DAYCARE: 'daycare',
  DIETITIAN: 'dietitian',
  FEMALE: 'female',
  MALE: 'male',
  GROUP: 'group',
  LOCATION: 'location',
  NEW_EQUIPMENT: 'new_equipment',
  OUTDOOR: 'outdoor',
  PARKING: 'parking',
  PHYSIO: 'physio',
  POWER: 'power',
  SAUNA: 'sauna',
  SHOWERS: 'showers',
  SCALE: 'scale',
  SPACIOUS: 'spacious',
  SPINNING: 'spinning',
  SQUASH: 'squash',
  TOWEL: 'towel',
  TRAIN_STATION: 'train_station',
  UNIQUE_BUILDING: 'unique_building',
  VIRTUAL: 'virtual',
  YOGA: 'yoga',
};

const MAX_IMAGE_FILE_SIZE = 20 * 1024 * 1024;

module.exports = {
  CONTENT_TYPES,
  CLUB_ATTRIBUTE_ICONS,
  MAX_IMAGE_FILE_SIZE,
};
