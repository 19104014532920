import { ITemporaryOffer as ITemporaryOfferEntry } from 'src/features/pg-funnel/services/contentful/types/generated/contentful';
import { mapLink } from 'src/features/shared/contentful/mappers/mapLink';
import { ITemporaryOffer } from 'src/features/shared/contentful/types/ITemporaryOffer';

export default function mapTemporaryOffer(
  data: ITemporaryOfferEntry,
): ITemporaryOffer | undefined {
  if (!data) {
    return undefined;
  }
  return {
    icon: data.fields.icon,
    title: data.fields.title,
    linkTitle: data.fields.linkTitle,
    link: mapLink(data.fields.link, data.fields.linkTitle),
    positionBelowHeader: data.fields.positionBelowHeader,
    closeIcon: data.fields.closeIcon,
    openInNewTab: data.fields.openInNewTab,
  };
}
