import { createResolvableActionsDomain } from '@touchtribe/redux-helpers'
import { DOMAIN } from './constants'

const createResolvableActions = createResolvableActionsDomain(DOMAIN)

export const [
  fetchMembershipsByClubId,
  fetchMembershipsByClubIdSuccess,
  fetchMembershipsByClubIdFail
] = createResolvableActions('fetchMembershipsByClubId', {
  init: (clubId) => ({ clubId }),
  resolve: (memberships, clubId) => ({ memberships, clubId }),
  reject: (statusCode, message) => ({ statusCode, message })
})
