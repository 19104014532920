import classNames from 'classnames';

import styles from './Section.module.scss';
import { ComponentProps } from 'react';

export interface ISectionProps extends ComponentProps<'section'> {
  hasNoBottomMargin?: boolean;
  hasImage?: boolean;
  forceMobileView?: boolean;
}

export const Section = ({
  children,
  className,
  hasNoBottomMargin = false,
  hasImage = false,
  forceMobileView,
  ...otherProps
}: ISectionProps) => (
  <section
    className={classNames(
      styles.section,
      forceMobileView && styles.forceMobileView,
      {
        [styles['no-bottom-margin']]: hasNoBottomMargin,
        [styles['no-image']]: !hasImage,
      },
      className,
    )}
    {...otherProps}
  >
    {children}
  </section>
);
