import mapImage from '@services/contentful/utils/map-image'
import { IQuoteSectionResponse } from '@features/shared/contentful/types/IQuoteSectionResponse'
import { IQuoteSection } from '@features/shared/contentful/types/IQuoteSection'
import { MappedImage } from '@features/pg-funnel/services/contentful/types'

export default function mapQuoteSection (data: IQuoteSectionResponse): IQuoteSection {
  const {
    authorPicture, authorName, authorFunction, quote, anchorLinkTitle
  } = data

  return {
    authorName,
    authorFunction,
    quote,
    authorPicture: {
      ...mapImage(authorPicture, [ 120, 289, 381, 476 ]),
      title: authorPicture.fields.title,
      description: authorPicture.fields.description
    } as MappedImage,
    anchorLinkTitle

  }
}
