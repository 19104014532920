import { IMenu as IContentfulMenu } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import { stringToCamelCase } from '@features/shared/utils/strings/conversion';
import { isAvailable } from 'src/utils/is-available';
import { IMenu } from '../types/IMenu';
import { mapMenuItem } from './mapMenuItem';

export function mapMenu(data: IContentfulMenu): IMenu {
  const location = stringToCamelCase(data.fields.location);
  const items = data.fields.items.map(mapMenuItem).filter(isAvailable);

  return {
    id: data.fields.name,
    items,
    location,
    subtitle: data.fields.subtitle,
  };
}
