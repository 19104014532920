import { IGenericContentSection } from '@features/shared/contentful/types/IGenericContentSection'

export default function mapGenericContentSection (data: IGenericContentSection) {
  return {
    componentType: data.componentType,
    title: data.title,
    description: data.description,
    anchorLinkTitle: data.anchorLinkTitle
  }
}
