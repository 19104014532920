import mapImage from 'services/contentful/utils/map-image'

export default function mapUsps (items) {
  if (!items) return
  return items.map((item) => {
    if (!item.fields) return
    return {
      id: item.sys.id,
      title: item.fields.title,
      image: mapImage(item.fields.image)
    }
  })
}
