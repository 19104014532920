import { isCamelCase } from './check';

export function stringToCamelCase(string: string): string {
  if (isCamelCase(string)) {
    return string;
  }

  return string
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
}

export function stringToPascalCase(string: string): string {
  const result = string.replace(/[^a-zA-Z0-9]+(.)/g, (_, chr) =>
    chr.toUpperCase(),
  );
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function addSpaceBeforeCapitalLetter(string: string): string {
  return string.replace(/([A-Z])/g, ' $1').trim();
}
