/* eslint-disable @typescript-eslint/ban-ts-comment */

type DeepObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function deepRemoveUndefined(obj: any): DeepObject {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(deepRemoveUndefined);
  }

  const result = {};
  for (const key in obj) {
    const value = deepRemoveUndefined(obj[key]);
    if (value !== undefined) {
      // @ts-ignore
      result[key] = value;
    }
  }
  return result;
}
