import { IImageSectionFields } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import { IImageSection } from '@features/shared/contentful/types/IImageSection';
import { AlignmentOption } from '@features/shared/types/enums/AlignmentOption';
import mapImage from '@services/contentful/utils/map-image';
import { mapLink } from './mapLink';

export const mapImageSection = (data: IImageSectionFields): IImageSection => ({
  image: data.image ? mapImage(data.image) : null,
  displayAs: data.displayAs,
  alignImage: data.imageAlignment
    ? AlignmentOption[data.imageAlignment]
    : // Required field but fallback for existing image sections
      AlignmentOption.Left,
  title: data.title,
  // @TODO - need to check and solve circular dependency issue, therefore the extra check on 'fields'
  link:
    data.link && data.link.fields
      ? mapLink(data.link, data.linkLabel || '')
      : undefined,
  description: data.description,
  anchorLinkTitle: data.anchorLinkTitle,
  backgroundColor: data.backgroundColor,
  secondaryLink:
    data.secondaryLink && data.secondaryLink.fields
      ? mapLink(data.secondaryLink, data.secondaryLinkLabel || '')
      : undefined,
  secondaryLinkDisplayAs: data.secondaryLinkDisplayAs,
});
