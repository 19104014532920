import { createSelector } from 'reselect'
import reducer from './reducers'
import getPropSelector from 'utils/redux/helpers/get-prop-selector'

export const getState = state => state[reducer] || {}

export const getMemberships = createSelector(
  getState,
  (state) => state.memberships,
  (memberships) => memberships.items
)

export const getClubIdProp = getPropSelector('clubId')
export const getType = getPropSelector('type')
export const getContractPeriod = getPropSelector('contractPeriod')

export const getMembershipsByClubId = createSelector(
  getMemberships,
  getClubIdProp,
  (memberships, clubId) => memberships[clubId]
)

export const getMembershipByClubIdAndType = createSelector(
  getMembershipsByClubId,
  getType,
  (memberships, type) => memberships && memberships[type]
)

export const getMembershipContractPeriod = createSelector(
  getMembershipsByClubId,
  getType,
  getContractPeriod,
  (memberships, type, contractPeriod) => memberships && memberships[type] && memberships[type].contractPeriods && memberships[type].contractPeriods[contractPeriod]
)
