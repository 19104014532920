import { Button } from '@features/shared/components/button';
import { Section } from '@features/shared/components/layout';
import styles from './GenericError.module.scss';
import Image from 'next/image';
import { Body, H1 } from 'src/features/shared/components/typography';
import classNames from 'classnames';

export interface IErrorPageProps {
  title: string;
  description: string;
  buttonLabel: string;
  onClick: () => void;
  iconSrc?: string;
  className?: string;
}

const DEFAULT_ICON = '/static/images/sportcity/error-page/no-connection.svg';

export const GenericError = ({
  title,
  description,
  buttonLabel,
  onClick,
  iconSrc = DEFAULT_ICON,
  className,
}: IErrorPageProps) => (
  <Section className={classNames(styles.section, className)}>
    <div className={styles.container}>
      <Image
        width={100}
        height={100}
        src={iconSrc}
        alt="error-icon"
        className={styles.image}
      />

      <H1 className={styles.title}>{title}</H1>

      <Body className={styles.textCenter}>{description}</Body>

      <Button onClick={onClick}>{buttonLabel}</Button>
    </div>
  </Section>
);
