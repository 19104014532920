import {
  takeEvery,
  put,
  call,
  select
} from 'redux-saga/effects'
import * as Actions from './actions'
import * as Selectors from './selectors'
import * as ProxyService from 'services/proxy'

export default function * clubsSagas () {
  yield takeEvery(Actions.fetchClubs, onFetchClubs)
  yield takeEvery(Actions.fetchClubById, onFetchClubById)
}

function * onFetchClubs ({
  resolve,
  reject
}) {
  const clubs = yield select(Selectors.getClubs)
  if (Object.keys(clubs).length) {
    yield put(resolve(clubs))
  }

  try {
    const { data } = yield call(ProxyService.getClubs)
    yield put(resolve(data))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}

function * onFetchClubById ({
  resolve,
  reject,
  id
}) {
  const club = yield select(Selectors.getClubById, { id })
  if (club) {
    yield put(resolve(club))
  }

  try {
    const { data } = yield call(ProxyService.getClubById, id)
    yield put(resolve(data))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}
