import clubsReducer from 'domains/clubs/reducers';
import cmsReducer from 'domains/cms/reducers';
import membershipsReducer from 'domains/memberships/reducers';
import userReducer from 'domains/user/reducers';
import contractsReducer from 'features/pg-funnel/domains/contracts/reducers';
import funnelSlice from 'features/pg-funnel/store/slice';

const reducers = {
  [cmsReducer]: cmsReducer,
  [clubsReducer]: clubsReducer,
  [membershipsReducer]: membershipsReducer,
  [userReducer]: userReducer,
  [contractsReducer]: contractsReducer,
  [funnelSlice.name]: funnelSlice.reducer,
};

Object.freeze(reducers);

export default reducers;
