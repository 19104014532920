import { IPageHeaderSection } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import { mapUsps } from '@features/pg-funnel/services/contentful/utils/map-usps';
import { DEFAULT_IMAGE_SIZES } from '@features/shared/constants/defaultImageSizes';
import { mapLink } from '@features/shared/contentful/mappers/mapLink';
import { getResponsiveImageSizes } from '@features/shared/contentful/utils/getResponsiveImageSizes';

import { MappedImage } from 'src/features/pg-funnel/services/contentful/types';
import {
  IPageHeader,
  PageHeaderBackgroundStyle,
} from 'src/features/shared/contentful/types/IPageHeader';
import mapImage from './map-image';

export function mapPageHeader(data: IPageHeaderSection): IPageHeader | null {
  if (!data) return null;
  const sizes = [375, 768, 1440, 1920];
  return {
    id: data.sys.id,
    type: 'pageHeader',
    title: data.fields.title,
    subtitle: data.fields.subtitle,
    primaryButton:
      data.fields.primaryButton &&
      mapLink(data.fields.primaryButton, data.fields.primaryButtonLabel),
    secondaryButton:
      data.fields.secondaryButton &&
      mapLink(data.fields.secondaryButton, data.fields.secondaryButtonLabel),
    description: data.fields.description,
    backgroundStyle: data.fields.backgroundStyle as PageHeaderBackgroundStyle,
    image: mapImage(data.fields.image, sizes, 100) as MappedImage,
    mobileImage: mapImage(data.fields.mobileImage, sizes, 100) as MappedImage,
    mainImageSizes: getResponsiveImageSizes(sizes),
    collageImages: data.fields.collageImages?.map(
      (asset) => mapImage(asset, DEFAULT_IMAGE_SIZES, 100) as MappedImage,
    ),
    contentAlignment: data.fields.contentAlignment,
    hasClubSearch: data.fields.hasClubSearch,
    hasFontColorLight: data.fields.hasFontColorLight,
    usps: mapUsps(data.fields.usps?.map((item) => item.fields)),
  };
}
