export const ROUTES = {
  DISCOUNT: '/v2/odata/ContractDiscountDefinitions',
  PAYMENT_PLANS: '/v2/odata/PaymentPlans',
  CLUBS: '/v2/odata/Clubs',
  CLUB_FACILITIES: '/v2/odata/ClubFacilities',
  CLUB_OPENING_HOURS: '/v2/odata/ClubOpeningHours',
  CLUB_OPENING_HOURS_EXCEPTIONS: '/v2/odata/ClubOpeningHoursExceptions',
  CLUB_PICTURES: '/v2/odata/ClubPhotos',
  DAY_PASS: '/v2/Members/AddContractMember',
  GET_MEMBERSHIP_OPTIONS: '/v2/odata/Clubs',
  SIGN_UP: '/v2/Members/AddContractMember',
  PAYMENT: '/v2/ClientPortal/GeneratePaymentLink',
  SIMULATE_NEW_CONTRACT: '/v2/Contracts/SimulateNewContract'
}
