import { ILinkList } from '../types/ILinkList'
import { ILinkListResponse } from '../types/ILinkListResponse'
import mapImageBanner from './mapImageBanner'
import { mapMenu } from './mapMenu'

export default function mapLinkListSection (data: ILinkListResponse): ILinkList {
  return {
    title: data.title,
    menuItems: data.menuItems.map(mapMenu),
    imageBanner: data.imageBannerMedia ? mapImageBanner(data) : null
  }
}
