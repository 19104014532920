import { createResolvableActionsDomain } from '@touchtribe/redux-helpers'
import { DOMAIN } from './constants'

const createResolvableActions = createResolvableActionsDomain(DOMAIN)

export const [
  fetchClubs,
  fetchClubsSuccess,
  fetchClubsFail
] = createResolvableActions('fetchClubs', {
  init: () => ({}),
  resolve: (clubs) => ({ clubs }),
  reject: (statusCode, message) => ({ statusCode, message })
})

export const [
  fetchClubById,
  fetchClubByIdSuccess,
  fetchClubByIdFail
] = createResolvableActions('fetchClubById', {
  init: (id) => ({ id }),
  resolve: (club) => ({ club }),
  reject: (statusCode, message) => ({ statusCode, message })
})
