import mapImage from 'services/contentful/utils/map-image'

export default function mapImageSliderItems (items) {
  if (!items) return []
  return items.map(item => {
    const {
      primaryImage, secondaryImage, title, description
    } = item.fields
    return {
      id: item.sys.id,
      primaryImage: primaryImage ? {
        ...mapImage(primaryImage, [ 1200, 289, 381, 476 ]),
        title: primaryImage.fields.title,
        description: primaryImage.fields.description
      } : null,
      secondaryImage: secondaryImage ? {
        ...mapImage(secondaryImage, [ 600, 289, 381, 476 ]),
        title: secondaryImage.fields.title,
        description: secondaryImage.fields.description
      } : null,
      secondaryImageLarge: secondaryImage ? {
        ...mapImage(secondaryImage, [ 1200, 289, 381, 476 ]),
        title: secondaryImage.fields.title,
        description: secondaryImage.fields.description
      } : null,
      title,
      description
    }
  })
}
