import mapImage from './map-image';
import mapCheckoutUsps from './map-checkout-usps';
import { IMembershipContentSection } from '@features/pg-funnel/services/contentful/types/generated/contentful';
import { IMembershipItem } from '@features/shared/contentful/types/IMembershipItem';
import { MappedImage } from '@features/pg-funnel/services/contentful/types';
import { mapLink } from 'src/features/shared/contentful/mappers/mapLink';

function mapMembership(membership: IMembershipContentSection): IMembershipItem {
  const { fields } = membership;
  return {
    title: fields.title,
    subtitle: fields.subtitle,
    image: mapImage(fields.image, [343, 570, 366, 486]) as MappedImage,
    price: fields.price,
    priceLabel: fields.priceLabel,
    priceDescription: fields.priceDescription,
    beforePrice: fields.beforePrice,
    description: fields.description,
    usps: mapCheckoutUsps(fields.usps),
    link: mapLink(fields.link, fields.buttonLabel),
    buttonStyle: fields.buttonStyle || 'Primary',
    badge: fields.badge,
  };
}

export default function mapMemberships(data: IMembershipContentSection[]) {
  if (!data) return null;
  return data.map((membership) => mapMembership(membership)).filter(Boolean);
}
