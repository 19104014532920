export default function mapCheckoutUsps (usps) {
  if (!usps) return

  return usps.map((usp) => {
    if (!usp.fields) return
    return {
      id: usp.sys.id,
      description: usp.fields.description,
      isHighlighted: usp.fields.highlight || null,
      icon: usp.fields.icon,
      isGreyedOut: usp.fields.isGreyedOut,
      isTag: usp.fields.isTag,
      tooltip: (usp.fields.tooltipTitle || usp.fields.tooltipDescription) ? {
        title: usp.fields.tooltipTitle,
        description: usp.fields.tooltipDescription
      } : null
    }
  }).filter(Boolean)
}
