import { Image } from '@features/pg-funnel/services/contentful/types'
import {
  IClubCard as IClubCardContentful
} from '@features/pg-funnel/services/contentful/types/generated/contentful'
import theme from 'src/config/theme'
import { getAllClubImages } from 'src/features/club-page/utils/get-all-club-images'
import clubIdBySubscriptionType from 'src/features/pg-funnel/config/club-id-by-subscription-type'
import { IClubCard } from 'src/features/shared/contentful/types/IClubCard'
import mapCheckoutUspsSection from 'src/services/contentful/utils/map-checkout-usps-section'
import mapContentBlocks from 'src/services/contentful/utils/map-content-blocks'
import mapImage from 'src/services/contentful/utils/map-image'

export function mapClubCard (data: IClubCardContentful): IClubCard {
  const clubHeaderMedia = data.fields.clubHeaderMedia && mapImage(data.fields.clubHeaderMedia)
  const contentBlocks = mapContentBlocks(data.fields.contentBlocks)
  const clubId = data.fields.pgClubId
  const themeName = theme.name
  const perfectGymUrl = process.env.NEXT_PUBLIC_PG_URL || ''
  const environment = perfectGymUrl.includes('test') ? 'staging' : 'production'
  const configData = clubIdBySubscriptionType[environment]?.[themeName]?.default
  const clubDataFromConfigData = configData ? Object?.values(configData)?.find(item => item?.clubs?.includes(clubId)) : null

  return {
    slug: data.fields.slug,
    type: data.fields.type,
    title: data.fields.title,
    pgClubId: data.fields.pgClubId,
    clubId: data.fields.clubId,
    stagingClubId: data.fields.stagingClubId,
    clubHeaderMedia,
    usps: data.fields.usps && mapCheckoutUspsSection(data.fields.usps),
    headerImages: getAllClubImages(clubHeaderMedia as Image, contentBlocks),
    fromPrice: clubDataFromConfigData && clubDataFromConfigData.price,
    funnelLink: {
      link: clubDataFromConfigData ? clubDataFromConfigData?.link : '/checkout',
      promo: clubDataFromConfigData ? clubDataFromConfigData?.promo : ''
    }
  }
}

export function mapClubCards (data: IClubCardContentful[]) {
  return data.map(item => mapClubCard(item))
}
