import {
  takeEvery,
  put
} from 'redux-saga/effects'
import * as Actions from './actions'

export default function * siteSaga () {
  yield takeEvery(Actions.setGeoLocation, onSetGeoLocation)
}

function * onSetGeoLocation ({
  resolve,
  reject,
  geoLocation
}) {
  try {
    yield put(resolve(geoLocation))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}
