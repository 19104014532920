import mapImageSliderItems from './map-image-slider-items'

export default function mapImageSliderSection (data) {
  if (!data) return
  return {
    title: data.title,
    description: data.description,
    items: mapImageSliderItems(data.items),
    anchorLinkTitle: data.anchorLinkTitle
  }
}
