import { Link } from '@features/shared/components/link';
import { Button as ButtonTypography } from '@features/shared/components/typography';
import { ButtonType } from '@features/shared/types/enums/ButtonType';
import { ButtonVariant } from '@features/shared/types/enums/ButtonVariant';
import classNames from 'classnames';
import theme from 'config/theme';
import { UrlObject } from 'url';
import { Loading } from '../loading';
import styles from './Button.module.scss';

export interface IButtonProps
  extends Omit<React.HTMLProps<HTMLButtonElement>, 'size' | 'href'> {
  children: React.ReactNode | React.ReactNode[];
  onClick?: (e: React.MouseEvent) => void;
  type?: ButtonType;
  variant?: ButtonVariant;
  size?: 'sm' | 'md';
  isDisabled?: boolean;
  isFullWidth?: boolean;
  isLoading?: boolean;
  icon?: JSX.Element;
  iconPosition?: 'start' | 'end';
  accessibleLabel?: string;
  className?: string;
  fullWidth?: boolean;
  href?: string | UrlObject;
  isHtmlTag?: boolean;
  openInNewTab?: boolean;
  dataTestId?: string;
}

export const Button = ({
  onClick,
  children,
  type = ButtonType.Button,
  variant = ButtonVariant.Primary,
  size = 'md',
  isDisabled,
  isFullWidth,
  isLoading,
  icon,
  iconPosition = 'start',
  accessibleLabel,
  className,
  href,
  isHtmlTag,
  openInNewTab,
  dataTestId,
  ...props
}: IButtonProps) => {
  const isAnchor = !!href;
  const isSportcityPremium = theme.name === 'sportcitypremium';

  const buttonProps = {
    className: classNames(
      styles.button,
      {
        [styles.primary]: variant === ButtonVariant.Primary,
        [styles.secondary]: variant === ButtonVariant.Secondary,
        [styles.club]: variant === ButtonVariant.Club,
        [styles.inverted]: variant === ButtonVariant.Inverted,
        [styles.link]: variant === ButtonVariant.Link,
        [styles.ghost]: variant === ButtonVariant.Ghost,
        [styles.secondaryInverted]: variant === ButtonVariant.SecondaryInverted,
        [styles.primaryInverted]: variant === ButtonVariant.PrimaryInverted,
        [styles.hasIcon]: !!icon,
        [styles.disabled]: !!isDisabled,
        [styles.fullWidth]: !!isFullWidth,
        [styles.small]: size === 'sm',
        [styles.medium]: size === 'md',
        [styles['text-white']]: !!isSportcityPremium,
      },
      className,
    ),
    type: isAnchor ? undefined : type,
    'aria-label': accessibleLabel,
    onClick: isAnchor ? undefined : onClick,
    disabled: isDisabled,
    ...props,
  };

  const buttonContent = isLoading ? (
    <Loading isSecondary={variant === ButtonVariant.Primary} height={16} />
  ) : (
    <>
      {iconPosition === 'start' && icon}
      {variant === ButtonVariant.Club ? (
        <span>{children}</span>
      ) : (
        <ButtonTypography>{children}</ButtonTypography>
      )}
      {iconPosition === 'end' && icon}
    </>
  );

  /**
   * When href is supplied but button component is used we want to render a link that is
   * displayed as a button
   */
  return isAnchor ? (
    <Link
      href={href}
      className={buttonProps.className}
      openInNewTab={openInNewTab}
      isHtmlTag={isHtmlTag}
      dataTestId={dataTestId}
      onClick={onClick}
    >
      {buttonContent}
    </Link>
  ) : (
    <button data-testid={dataTestId} {...buttonProps}>
      {buttonContent}
    </button>
  );
};
