interface IContentfulTag {
  sys: {
    type: string
    linkType: string
    id: string
  }
}

export const getPageTags = (tags: IContentfulTag[]): string[] => {
  const resultTags: string[] = []

  tags.map((tag) => {
    if (tag.sys.linkType === 'Tag') {
      resultTags.push(tag.sys.id)
    }
  })

  return resultTags
}
