import theme from 'config/theme';
import { useIntl } from 'domains/i18n';
import { ErrorProps } from 'next/error';
import { useRouter } from 'next/router';
import { GenericError } from 'src/features/error-page/components/generic-error';
import { Footer } from 'src/features/layout/footer/components';
import { Header } from 'src/features/layout/header/Header';
import { IMappedMenus } from 'src/features/layout/menu/types/IMappedMenus';
import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import { Error404 } from '../error-400-page';

export interface IErrorHandlerProps extends ErrorProps {
  headerMenus: IMappedMenus;
  page404Data?: IContentPage;
}

export function ErrorHandler({ statusCode, headerMenus }: IErrorHandlerProps) {
  const router = useRouter();
  const isError404 = statusCode === 404;
  const { formatMessage } = useIntl();
  const isSportCity = theme.name === 'sportcity';

  return (
    <>
      <Header menus={headerMenus} isAlternative={isSportCity} />
      {isError404 ? (
        <Error404 />
      ) : (
        <GenericError
          title={formatMessage('error-500-page.title')}
          description={formatMessage('error-500-page.description')}
          buttonLabel={formatMessage('error-500-page.reload-label')}
          onClick={() => router.reload()}
        />
      )}
      <Footer />
    </>
  );
}
