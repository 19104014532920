import mapImage from './map-image'

export default function mapPaymentCards (data) {
  if (!data) return
  return data.map(data => {
    return {
      title: data.fields.title,
      description: data.fields.description,
      image: mapImage(data.fields.icon)
    }
  })
}
