import theme from 'config/theme';
import { useIntl } from 'src/domains/i18n';
import { Image } from 'src/features/shared/components/image';
import { FontColor, H5 } from 'src/features/shared/components/typography';
import styles from './index.module.scss';
import { Link } from 'src/features/shared/components/link';
import classNames from 'classnames';

const appStore = {
  src: '/static/images/shared/app-store-dark.svg',
  srcSet: '/static/images/shared/app-store-dark.svg',
  alt: 'app-store',
};

const playStore = {
  src: '/static/images/shared/google-play.svg',
  srcSet: '/static/images/shared/google-play.svg',
  alt: 'play-store',
};

export const AppButtons = () => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.appButtonsContainer}>
      <H5 className={styles.columnTitle} color={FontColor.Neutral06}>
        {formatMessage('home.app-download.app')}
      </H5>
      <div className={styles.buttons}>
        <Link openInNewTab href={theme.app.appStore} className={styles.button}>
          <Image
            className={classNames(styles.marginRightM, styles.appStoreImage)}
            image={appStore}
          />
        </Link>
        <Link openInNewTab href={theme.app.playStore} className={styles.button}>
          <Image className={styles.playStoreImage} image={playStore} />
        </Link>
      </div>
    </div>
  );
};
