import { ICardSectionFields } from 'src/features/pg-funnel/services/contentful/types/generated/contentful';
import { ICardSection } from 'src/features/shared/contentful/types/ICardSection';
import { mapCardItem } from 'src/services/contentful/utils/map-card-item';

export function mapCardSection(data: ICardSectionFields): ICardSection {
  return {
    title: data.title,
    items: mapCardItem(data.items),
  };
}
