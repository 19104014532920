import Axios from 'axios'

export const PGClient = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_PG_URL,
  headers: {
    'X-Client-Id': process.env.PG_CLIENT_ID,
    'X-Client-Secret': process.env.PG_CLIENT_SECRET
  }
})

