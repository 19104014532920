import createImmerReducer from 'utils/redux/helpers/create-immer-reducer'
import * as Actions from './actions'

const initialState = {
  geoLocation: false
}

function onSetGeoLocationSuccess (state, { geoLocation }) {
  state.geoLocation = geoLocation
}

export default createImmerReducer('user', {
  [Actions.setGeoLocationSuccess]: onSetGeoLocationSuccess
}, initialState)
