import Icons from 'features/shared/components/icons';
import { Layout } from '@features/shared/components/layout';
import Link from 'next/link';
import { useState } from 'react';
import { useIntl } from 'src/domains/i18n';
import {
  Body,
  FontColor,
  FontWeight,
  Link as LinkTypography,
} from 'src/features/shared/components/typography';
import { ILink } from 'src/features/shared/contentful/types/ILink';
import { GA4_EVENTS, pushGA4Event } from 'src/features/shared/utils/ga4';
import styles from './index.module.scss';

export interface ITemporaryOfferProps {
  icon: keyof typeof Icons;
  title: string;
  linkTitle: string;
  link?: ILink;
  closeIcon: boolean;
  openInNewTab: boolean;
  positionBelowHeader: boolean;
}

export const TemporaryOffer = ({
  icon,
  title,
  linkTitle,
  link,
  closeIcon,
  openInNewTab,
}: ITemporaryOfferProps) => {
  const { formatMessage } = useIntl();
  const Icon = Icons[icon];
  const regex = /\*([^*]+)\*/;
  const styledTitle = title
    ? title.includes('*')
      ? title.replace(regex, `<em>${'$1'}</em>`)
      : title
    : '';
  const [showTemporaryOffer, setShowTemporaryOffer] = useState(true);

  const closeTemporaryOffer = () => {
    setShowTemporaryOffer(false);
    pushGA4Event(GA4_EVENTS.trackEvent, { event_name: 'close_promobanner' });
  };

  const pushEvent = () =>
    pushGA4Event(GA4_EVENTS.trackEvent, {
      event_name: 'click_promobanner',
    });

  return (
    <>
      {showTemporaryOffer ? (
        <div className={styles.container}>
          <Layout.Container>
            <div className={styles.wrapper}>
              {icon && <Icon className={styles.icon} />}
              <Body
                component="div"
                color={FontColor.Neutral06}
                fontWeight={FontWeight.Bold}
              >
                {link?.href ? (
                  <Link
                    className={styles.link}
                    href={link.href}
                    onClick={pushEvent}
                    target={openInNewTab ? '_blank' : '_self'}
                  >
                    <LinkTypography
                      className={styles.description}
                      component="span"
                      fontWeight={FontWeight.Bold}
                      color={FontColor.Neutral06}
                    >
                      {styledTitle}{' '}
                    </LinkTypography>
                    <LinkTypography
                      className={styles.linkLabel}
                      component="span"
                      fontWeight={FontWeight.Bold}
                      color={FontColor.Neutral06}
                    >
                      {linkTitle}
                    </LinkTypography>
                  </Link>
                ) : (
                  <LinkTypography
                    className={styles.description}
                    component="span"
                    fontWeight={FontWeight.Bold}
                    color={FontColor.Neutral06}
                  >
                    {styledTitle}
                  </LinkTypography>
                )}
              </Body>
              {closeIcon && (
                <button
                  onClick={closeTemporaryOffer}
                  aria-label={formatMessage('general.close')}
                  className={styles.closeIcon}
                >
                  <Icons.Cross />
                </button>
              )}
            </div>
          </Layout.Container>
        </div>
      ) : null}
    </>
  );
};
