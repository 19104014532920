import { createSelector } from 'reselect'
import reducer from './reducers'
import getPropSelector from 'utils/redux/helpers/get-prop-selector'

export const getState = state => state[reducer] || {}

export const getPages = createSelector(
  getState,
  (state) => state.pages
)

export const getMenus = createSelector(
  getState,
  (state) => state.menus
)

export const getFooterLangProp = getPropSelector('lang')
export const getPageSlugProp = getPropSelector('slug')
export const getPageLangProp = getPropSelector('lang')

export const getMenuLangProp = getPropSelector('lang')
export const getMenuLocationProp = getPropSelector('location')

export const getCheckoutLangProp = getPropSelector('lang')

export const getPageBySlug = createSelector(
  getPages,
  getPageSlugProp,
  getPageLangProp,
  (pages, slug, lang) => pages[`${lang}/${slug}`]
)

export const getFooter = createSelector(
  getState,
  getFooterLangProp,
  (state, lang) => state.footer[lang]
)

export const getMenuByLang = createSelector(
  getState,
  getMenuLangProp,
  (state, lang) => state.menus[lang]
)

export const getMenuByLocation = createSelector(
  getState,
  getMenuLangProp,
  getMenuLocationProp,
  (state, lang, location) => state.menus[lang][location]
)

export const getCheckoutByLang = createSelector(
  getState,
  getCheckoutLangProp,
  (state, lang) => state.checkout[lang]
)
