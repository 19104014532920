import { stringToCamelCase } from 'features/shared/utils/strings/conversion'
import { mapMenuItem } from 'src/features/shared/contentful/mappers/mapMenuItem'
import { isAvailable } from 'src/utils/is-available'

export function mapMenus (data) {
  return data.reduce((menus, menu) => {
    const location = stringToCamelCase(menu.fields.location)
    menus = {
      ...menus,
      [location]: {
        items: menu.fields.items.map(mapMenuItem).filter(isAvailable),
        location
      }
    }
    return menus
  }, {})
}
