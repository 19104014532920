import { useQuery } from 'react-query'

import useLang from '@utils/hooks/use-lang'
import { STALE_TIME } from '@features/shared/constants/staleTime'

import { IFooter } from '../types/IFooter'
import { fetchFooterData } from '../services/proxy/fetch-footer-data'

export const useFooter = () => {
  const { lang } = useLang()

  return useQuery<IFooter, Error>(
    [ 'main-footer', lang ],
    async () => fetchFooterData(lang),
    {
      staleTime: STALE_TIME,
      cacheTime: STALE_TIME * 1.5
    }
  )
}
