import { RefObject, useEffect } from 'react'

type Handler = (event: MouseEvent | TouchEvent) => void

function useOnClickOutside<T extends HTMLElement = HTMLElement> (
  ref: RefObject<T>,
  handler: Handler
): void {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        handler(e)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('touchstart', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('touchstart', handleClickOutside)
    }
  }, [ ref ])
}

export default useOnClickOutside
