import { IContentPage } from 'src/features/shared/contentful/types/IContentPage';
import { ITemporaryOffer } from 'src/features/shared/contentful/types/ITemporaryOffer';
import { mapVideoPageHeader } from 'src/services/contentful/utils/map-video-page-header';
import { getPageTags } from 'src/utils/getPageTags';
import { CONTENT_TYPES } from '../../../../contentful/constants';
import mapContentBlocks from './map-content-blocks';
import { mapPageLanguages } from './map-languages';
import { mapPageHeader } from './map-page-header';
import mapTemporaryOffer from './map-temporary-offer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapPage(data: any, status?: string): IContentPage {
  const pageHeaderContentType = data.fields.pageHeader.sys.contentType.sys.id;

  return {
    id: data.sys.id,
    slug: data.fields.slug,
    metaTitle: data.fields.metaTitle,
    metaDescription: data.fields.metaDescription,
    languages: data.languages && mapPageLanguages(data.languages),
    type: data.sys.contentType.sys.id,
    title: data.fields.title,
    pageHeader:
      pageHeaderContentType === CONTENT_TYPES.PAGE_HEADER
        ? mapPageHeader(data.fields.pageHeader)
        : mapVideoPageHeader(data.fields.pageHeader),
    temporaryOffer: mapTemporaryOffer(
      data.fields.temporaryOffer,
    ) as ITemporaryOffer,
    contentBlocks: mapContentBlocks(data.fields.contentBlocks),
    isPaymentSuccess: status === 'success',
    showConfetti: data.fields.showConfetti,
    noIndex: data.fields.noIndex,
    pageType: data.fields.pageType,
    contentfulTags: getPageTags(data.metadata.tags),
    contentTableTitle: data.fields.contentTableTitle,
  };
}
