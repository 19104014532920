import { IMenu } from '@features/shared/contentful/types/IMenu'
import { IMappedMenus } from '../types/IMappedMenus'

// Map all menus the site uses and return an object keyed on location
export function mapSiteMenus (data: IMenu[]): IMappedMenus {
  return data.reduce((menus, menu) => {
    menus = {
      ...menus,
      [menu.location]: {
        items: menu.items,
        location: menu.location
      }
    }
    return menus
  }, {})
}
