import Icons from 'features/shared/components/icons';
import { Link } from 'features/shared/components/link';
import configTheme from '@config/theme';
import { useIntl } from '@domains/i18n';
import { useMemo } from 'react';
import styles from './LoginLink.module.scss';
import classNames from 'classnames';

interface ILoginLinkProps {
  isLight?: boolean;
}

export function LoginLink({ isLight = false }: ILoginLinkProps) {
  const { formatMessage } = useIntl();
  const label = useMemo(() => formatMessage('login'), [formatMessage]);
  return (
    <Link
      openInNewTab
      href={configTheme.loginUrl}
      icon={Icons.User}
      className={classNames(styles.link, { [styles.light]: isLight })}
    >
      {label}
    </Link>
  );
}
