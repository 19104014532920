import { createResolvableActionsDomain } from '@touchtribe/redux-helpers'

const createResolvableActions = createResolvableActionsDomain('locations')

export const [
  setGeoLocation,
  setGeoLocationSuccess,
  setGeoLocationError
] = createResolvableActions('setGeoLocation', {
  init: (geoLocation) => ({ geoLocation }),
  resolve: (geoLocation) => ({ geoLocation }),
  reject: (error) => ({ error })
})
