import {
  takeEvery,
  put,
  call,
  select
} from 'redux-saga/effects'
import * as Actions from './actions'
import * as Selectors from './selectors'
import * as ProxyService from 'services/proxy'

export default function * membershipsSaga () {
  yield takeEvery(Actions.fetchMembershipsByClubId, onFetchMembershipsByClubId)
}

function * onFetchMembershipsByClubId ({
  resolve,
  reject,
  clubId
}) {
  const memberships = yield select(Selectors.getMembershipsByClubId, { clubId })
  if (memberships && memberships.length) {
    yield put(resolve())
  }

  try {
    const { data } = yield call(ProxyService.getMembershipsByClubId, clubId)
    yield put(resolve(data, clubId))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}
