import createImmerReducer from 'utils/redux/helpers/create-immer-reducer'
import * as Actions from './actions'
import { DOMAIN } from './constants'

const initialState = {
  items: {}
}

function onFetchMembershipsByClubIdSuccess (state, { memberships, clubId }) {
  state.items[clubId] = memberships
}

export default createImmerReducer(DOMAIN, {
  [Actions.fetchMembershipsByClubIdSuccess]: onFetchMembershipsByClubIdSuccess
}, initialState)
