import { IClubAttribute, IClubAttributeFields } from '@features/pg-funnel/services/contentful/types/generated/contentful'

export function mapClubAttribute (attribute: IClubAttribute): IClubAttributeFields {
  return {
    name: attribute.fields.name,
    title: attribute.fields.title,
    subtitle: attribute.fields.subtitle,
    icon: attribute.fields.icon
  }
}
