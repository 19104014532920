import { ILink as ILinkEntry } from 'src/features/pg-funnel/services/contentful/types/generated/contentful';
import { IContentfulPage } from 'src/features/shared/contentful/types/IContentfulPage';
import { CONTENT_TYPES } from 'src/services/contentful/constants';
import { ILink } from '../types/ILink';
import { CHECKOUT_URL } from 'src/features/pg-funnel/utils/constants';

function getSlug(slug: string, id: string): string {
  if (id === 'funnel') {
    return `/funnel/${slug}`;
  }

  if (id === CONTENT_TYPES.CHECKOUT) {
    return `/${CHECKOUT_URL}/${slug}`;
  }

  return `/${slug}`;
}

function mapContentTypeLink(link: ILinkEntry): {
  href: string;
  openInNewTab: boolean;
} {
  const internalLink = link.fields.internalLink;

  const slug = internalLink?.fields.slug || '';
  const contentType = internalLink?.sys.contentType.sys.id || '';

  const href = internalLink
    ? getSlug(slug, contentType)
    : link.fields.externalLink;

  return {
    href,
    openInNewTab: internalLink
      ? false
      : link.fields.openExternalLinkInNewTab || false,
  };
}

function mapEntryLink(link: IContentfulPage): {
  href: string;
  openInNewTab: boolean;
} {
  const href = getSlug(link.fields.slug || '', link.sys.contentType.sys.id);

  return {
    href,
    openInNewTab: false,
  };
}

export function mapLink(
  link: ILinkEntry | IContentfulPage,
  label: string,
): ILink | undefined {
  try {
    const mappedLink =
      'internalLink' in link.fields || 'externalLink' in link.fields
        ? mapContentTypeLink(link as ILinkEntry)
        : mapEntryLink(link as IContentfulPage);
    return {
      ...mappedLink,
      label,
    };
  } catch (error) {
    // In the case of circular reference the internalLink value is "circular ~.0"
    // causing error. This catch will prevent the page of breaking.
    return undefined;
  }
}
