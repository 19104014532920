import { mapLink } from 'src/features/shared/contentful/mappers/mapLink';

export default function mapVideoSection(data) {
  if (!data) return null;
  return {
    title: data.title,
    videoId: data.videoId,
    description: data.description,
    link: mapLink(data.link),
    linkLabel: data.linkLabel,
  };
}
