import clubsSaga from 'domains/clubs/sagas';
import cmsSaga from 'domains/cms/sagas';
import membershipsSaga from 'domains/memberships/sagas';
import userSaga from 'domains/user/sagas';
import contractSagas from 'features/pg-funnel/domains/contracts/sagas';

const sagas = [cmsSaga, clubsSaga, membershipsSaga, userSaga, contractSagas];

Object.freeze(sagas);

export default sagas;
