import classNames from 'classnames'

import { IMenuItem } from '@features/layout/footer/types/IMenuItem'

import { FooterLink } from '../footer-link'
import styles from './FooterMenu.module.scss'

export interface IFooterMenuProps {
  links: IMenuItem[];
  isAlignRight?: boolean;
  isSmallFontSize?: boolean;
  children?: React.ReactNode;
}

export const FooterMenu = ({
  links, isAlignRight, isSmallFontSize, children
}: IFooterMenuProps) => (
  <div
    className={classNames(
      styles.footerMenu,
      { [styles.alignRight]: isAlignRight }
    )}
  >
    {links.map(link => (
      <FooterLink
        href={link.href}
        label={link.label}
        isOpenInNewTab={link.isOpenInNewTab}
        isSmall={isSmallFontSize}
        key={link.id}
      />
    ))}

    {children || null}
  </div>
)
