import { Container, Row, Col } from '@features/shared/components/layout';

import { IFooterColumn } from '../../types/IFooterColumn';
import { PrimaryFooterColumn } from './primary-footer-column';
import useBreakpoints from 'src/utils/hooks/use-breakpoints';
import {
  LARGE_SCREEN_COLUMN_WIDTH,
  MAX_COLUMNS,
} from '../../constants/primary-columns';
import { LoginLink } from 'src/features/shared/components/login-link';
import styles from './index.module.scss';
import { useState } from 'react';
export interface IPrimaryFooterMenuProps {
  columns: IFooterColumn[];
}

export const PrimaryFooterMenu = ({ columns }: IPrimaryFooterMenuProps) => {
  const { currentBreakpoint, breakpoints } = useBreakpoints();
  const [openedColumn, setOpenedColumn] = useState<string | null>(null);

  const isMobile = currentBreakpoint === breakpoints.xs.name;
  const isLargeDesktop = currentBreakpoint === breakpoints.lg.name;

  // If less columns than MAX_COLUMNS we fill the space with an empty column
  const emptyColumnsCount = Math.max(MAX_COLUMNS - columns.length, 0);

  const onColumnClick = (columnId: string) => {
    if (columnId === openedColumn) {
      setOpenedColumn(null);
      return;
    }

    setOpenedColumn(columnId);
  };

  return (
    <Container>
      <Row>
        {columns.map((column) => (
          <PrimaryFooterColumn
            column={column}
            isMobile={isMobile}
            key={column.id}
            isOpen={openedColumn === column.id}
            onMobileClick={() => onColumnClick(column.id)}
          />
        ))}

        {emptyColumnsCount !== 0 && isLargeDesktop ? (
          <Col columns={`lg:${emptyColumnsCount * LARGE_SCREEN_COLUMN_WIDTH}`}>
            <></>
          </Col>
        ) : null}

        <Col
          columns={`sm:3 lg:${LARGE_SCREEN_COLUMN_WIDTH}`}
          className={styles.loginLinkColumn}
        >
          <LoginLink isLight />
        </Col>
      </Row>
    </Container>
  );
};
