import classNames from 'classnames'

import { Link } from 'src/features/shared/components/link'

import styles from './FooterLink.module.scss'
import { CHECKOUT_URL } from 'src/features/pg-funnel/utils/constants'

export interface IFooterLinkProps {
  href: string;
  label: string;
  isExtraLink?: boolean;
  isOpenInNewTab?: boolean;
  isSmall?: boolean;
}

export const FooterLink = ({
  href, label, isExtraLink = false, isOpenInNewTab = false, isSmall = false
}: IFooterLinkProps) => (
  <div className={classNames(styles.footerItem, { [styles.extraFooterItem]: isExtraLink })}>
    <Link
      isHtmlTag={href.startsWith(`/${CHECKOUT_URL}`)}
      className={classNames(
        styles.footerLink,
        { [styles.extraFooterLink]: isExtraLink, [styles.small]: isSmall }
      )}
      href={href}
      openInNewTab={isOpenInNewTab}
    >
      {label}
    </Link>
  </div>
)
