import { Col, Container, Row } from '@features/shared/components/layout';

import { AppButtons } from 'src/features/layout/footer/components/app-buttons';
import { Image } from 'src/features/shared/components/image';
import { Link } from 'src/features/shared/components/link';
import { useFooter } from '../hooks/use-footer';
import { LegalMenu } from './legal-menu';
import { PrimaryFooterMenu } from './primary-footer-menu/PrimaryFooterMenu';
import { FooterMenu } from './shared/footer-menu/FooterMenu';
import styles from './Footer.module.scss';

const imageData = {
  src: '/static/images/sportcity/logos/logo-light-slogan.svg',
  srcSet: '/static/images/sportcity/logos/logo-light-slogan.svg',
  alt: 'Sportcity logo',
};

export const Footer = () => {
  const { data: footer } = useFooter();

  if (!footer) {
    return null;
  }

  return (
    <footer className={styles.footer}>
      <PrimaryFooterMenu columns={footer.columns} />

      <Container className={styles.footerContainer}>
        <Row>
          <Col columns="sm:7 lg:9" className={styles.socialLinksColumn}>
            <FooterMenu links={footer.socialLinks} />
          </Col>
          <Col columns="sm:5 lg:3" className={styles.appButtonsColumn}>
            <AppButtons />
          </Col>
        </Row>
        <Row className={styles.bottomFooter}>
          <Col columns="xs:9 sm:4 lg:3">
            <Link openInNewTab={false} href="/" className={styles.button}>
              <Image className={styles.logo} image={imageData} />
            </Link>
          </Col>
          <Col columns="sm:8 lg:9">
            <LegalMenu links={footer.legalLinks} />
          </Col>
        </Row>
      </Container>
    </footer>
  );
};
