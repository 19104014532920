import { IMenuItem as IContentfulMenuItem } from '@features/pg-funnel/services/contentful/types/generated/contentful'
import { IMenuItem } from '../types/IMenuItem'
import { mapLink } from './mapLink'

export function mapMenuItem (data: IContentfulMenuItem): IMenuItem | undefined {
  const link =
    data.fields && data.fields.link
      ? mapLink(data.fields.link, data.fields.label)
      : undefined

  if (!link) {
    return undefined
  }

  return {
    link,
    icon: data.fields.icon,
    isSocial: data.fields.isSocial
  }
}
