import { IClubOverviewPage } from 'src/features/shared/contentful/types/IClubOverviewPage';
import { mapPageHeader } from './map-page-header';
import mapTemporaryOffer from './map-temporary-offer';
import { ITemporaryOffer } from 'src/features/shared/contentful/types/ITemporaryOffer';
import { IPageHeader } from 'src/features/shared/contentful/types/IPageHeader';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapClubOverviewPage(data: any): IClubOverviewPage {
  return {
    id: data.sys.id,
    type: data.sys.contentType.sys.id,
    title: data.fields.title,
    slug: data.fields.slug,
    metaTitle: data.fields.metaTitle,
    metaDescription: data.fields.metaDescription,
    intro: data.fields.intro,
    description: data.fields.description,
    temporaryOffer: mapTemporaryOffer(
      data.fields.temporaryOffer,
    ) as ITemporaryOffer,
    pageHeader: mapPageHeader(data.fields.pageHeader) as IPageHeader,
  };
}
