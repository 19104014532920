import createImmerReducer from 'utils/redux/helpers/create-immer-reducer'
import * as Actions from './actions'
import { DOMAIN } from './constants'

const initialState = {
  items: {}
}

function onFetchClubsSuccess (state, { clubs }) {
  const clubsArray = Object.entries(clubs)

  clubsArray.forEach(([ id, club ]) => {
    const { facilities, ...restClub } = club
    state.items[id] = Object.assign(state.items[id] || {}, restClub)

    // Only replace facilities when facilities is not empty
    if (facilities && Object.keys(facilities).length) {
      state.items[id].facilities = facilities
    }
  })
}

function onFetchClubByIdSuccess (state, { club }) {
  state.items[club.id] = Object.assign(state.items[club.id] || {}, club)
}

export default createImmerReducer(DOMAIN, {
  [Actions.fetchClubsSuccess]: onFetchClubsSuccess,
  [Actions.fetchClubByIdSuccess]: onFetchClubByIdSuccess
}, initialState)
