import { IJobVacanciesSection } from 'src/features/shared/contentful/types/IJobVacanciesSection';

export default function mapJobVacanciesSection(
  data: IJobVacanciesSection,
): IJobVacanciesSection {
  const { title, location, department } = data;

  return {
    title,
    location,
    department,
  };
}
