import theme from 'config/theme';
import ClubIdBySubscriptionType from 'features/pg-funnel/config/club-id-by-subscription-type';
import mapCheckoutUspsSection from 'services/contentful/utils/map-checkout-usps-section';
import mapContentBlocks from 'services/contentful/utils/map-content-blocks';
import mapImage from 'services/contentful/utils/map-image';
import { IClubContentPage } from 'src/features/shared/contentful/types/IClubContentPage';
import { ITemporaryOffer } from 'src/features/shared/contentful/types/ITemporaryOffer';
import {
  mapOpeningHours,
  mapOpeningHoursExceptions,
} from 'src/services/perfect-gym/utils/map-club';
import { mapClubAttribute } from './map-club-attribute';
import { mapPageLanguages } from './map-languages';
import mapTemporaryOffer from './map-temporary-offer';
import { IClubUspsBlock } from 'src/features/shared/contentful/types/IClubCard';
import mapLinkListSection from 'src/features/shared/contentful/mappers/mapLinkListSection';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapClubPage(data: any): IClubContentPage {
  const clubId = data.fields.pgClubId;
  const themeName = theme.name;
  const clubHeaderMedia =
    data.fields.clubHeaderMedia && mapImage(data.fields.clubHeaderMedia);
  const perfectGymUrl = process.env.NEXT_PUBLIC_PG_URL;
  const environment = perfectGymUrl?.includes('test')
    ? 'staging'
    : 'production';
  const configData =
    ClubIdBySubscriptionType[environment]?.[themeName]?.default;
  const clubDataFromConfigData = configData
    ? Object?.values(configData)?.find((item) => item?.clubs?.includes(clubId))
    : null;

  return {
    id: data.sys.id,
    slug: data.fields.slug,
    type: data.sys.contentType.sys.id,
    metaTitle: data.fields.metaTitle,
    metaDescription: data.fields.metaDescription,
    title: data.fields.title,
    intro: data.fields.intro,
    description: data.fields.description,
    highlightedAttributes: data.fields.highlightedAttributes
      ? data.fields.highlightedAttributes.map(mapClubAttribute)
      : [],
    clubId: data.fields.clubId,
    pgClubId: data.fields.pgClubId,
    temporaryOffer: mapTemporaryOffer(
      data.fields.temporaryOffer,
    ) as ITemporaryOffer,
    clubHeaderMedia,
    languages: data.languages && mapPageLanguages(data.languages),
    contentBlocks: mapContentBlocks(data.fields.contentBlocks),
    usps: mapCheckoutUspsSection(data.fields.usps) as IClubUspsBlock,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromPrice: clubDataFromConfigData?.price,
    showPhoneNumber: data.fields.showPhoneNumber,
    funnelLink: {
      link: clubDataFromConfigData ? clubDataFromConfigData?.link : '/checkout',
      promo: clubDataFromConfigData?.promo,
    },
    pageType: data.fields.pageType,
    openingHours: data.fields.openingHours
      ? mapOpeningHours(data.fields.openingHours)
      : [],
    openingHoursExceptions: data.fields.openingHoursExceptions
      ? mapOpeningHoursExceptions(data.fields.openingHoursExceptions)
      : [],
    seoLinks: data.fields.seoLinks
      ? mapLinkListSection(data.fields.seoLinks.fields)
      : null,
  };
}
