import { mapLink } from 'src/features/shared/contentful/mappers/mapLink'

import mapPaymentCards from './map-payment-cards'

export default function mapConfirmationDetailsSection (data) {
  if (!data) return
  return {
    title: data.title,
    description: data.description,
    cards: mapPaymentCards(data.cards),
    link: data.link && mapLink(data.link, data.linkLabel)
  }
}
