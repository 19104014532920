import React, { useEffect, useCallback, useState } from 'react';
import Router from 'next/router';
import styles from './PageLoadingIndicator.module.scss';

export function PageLoadingIndicator() {
  const [isLoading, setIsLoading] = useState(false);
  
  const onRouteChangeStart = useCallback(
    () => setIsLoading(true),
    [setIsLoading],
  );
  const onRouteChangeComplete = useCallback(
    () => setIsLoading(false),
    [setIsLoading],
  );

  useEffect(() => {
    Router.events.on('routeChangeStart', onRouteChangeStart);
    Router.events.on('routeChangeComplete', onRouteChangeComplete);
    Router.events.on('routeChangeError', onRouteChangeComplete);

    return () => {
      Router.events.off('routeChangeStart', onRouteChangeStart);
      Router.events.off('routeChangeComplete', onRouteChangeComplete);
      Router.events.off('routeChangeError', onRouteChangeComplete);
    };
  }, [onRouteChangeStart, onRouteChangeComplete]);

  return (
    isLoading && (
      <div className={styles.container}>
        <div className={styles.inner} />
      </div>
    )
  );
}
