import { mapClubAttribute } from './map-club-attribute'

export default function mapClubOffers (data) {
  if (!data) return
  return {
    title: data.title,
    clubOffer: data.clubOffer,
    sportsOffer: data.sportsOffer,
    servicesOffer: data.servicesOffer,
    groupLessonsOffer: data.groupLessonsOffer,
    highlightedAttributes: data.highlightedAttributes
      ? data.highlightedAttributes.map(mapClubAttribute)
      : [],
    anchorLinkTitle: data.anchorLinkTitle
  }
}
