import mapLinkListSection from '@features/shared/contentful/mappers/mapLinkListSection';
import mapDealSection from 'services/contentful/utils/map-deal-section';
import mapImageCarouselSection from 'services/contentful/utils/map-image-carousel-section';
import mapImageSliderSection from 'services/contentful/utils/map-image-slider-section';
import mapMapSection from 'services/contentful/utils/map-map-section';
import mapUspSection from 'services/contentful/utils/map-usp-section';
import mapClubOffers from './map-club-offers';
import mapConfirmationDetailsSection from './map-confirmation-details-section';
import mapGenericContentSection from './map-generic-content-section';
import mapMembershipSection from './map-membership-section';
import mapQuoteSection from './map-quote-section';
import mapSection from './map-section';
import mapVideoSection from './map-video-section';

/** TypeScript mappers */
import { mapImageSection } from '@features/shared/contentful/mappers/mapImageSection';
import { mapCardSection } from 'src/services/contentful/utils/map-card-section';
import { mapCardSlider } from 'src/services/contentful/utils/map-card-slider';
import mapJobVacanciesSection from 'src/services/contentful/utils/map-job-vacancies-section';

function mapContentBlock(block, type) {
  const contentBlockMap = {
    section: mapSection,
    confirmationDetails: mapConfirmationDetailsSection,
    membershipSection: mapMembershipSection,
    // @Note: 'newsSection' renamed to 'cardSection' due to Contentful's restrictions on changing content type ids.
    // Since content type ids cannot be altered in Contentful, 'newsSection' is retained in this mapping.
    newsSection: mapCardSection,
    cardSlider: mapCardSlider,
    videoSection: mapVideoSection,
    imageSection: mapImageSection,
    imageCarouselSection: mapImageCarouselSection,
    imageSliderSection: mapImageSliderSection,
    dealSection: mapDealSection,
    mapSection: mapMapSection,
    uspSection: mapUspSection,
    comparisonTable: () => {},
    linkList: mapLinkListSection,
    clubOffers: mapClubOffers,
    genericContentSection: mapGenericContentSection,
    quoteSection: mapQuoteSection,
    jobVacanciesSection: mapJobVacanciesSection,
  };

  if (!contentBlockMap[type]) return null;

  return {
    ...contentBlockMap[type](block.fields),
    type,
    id: block.sys.id,
  };
}

export default function mapContentBlocks(data) {
  if (data) {
    return data
      .map(
        (contentBlock) =>
          contentBlock?.fields &&
          mapContentBlock(contentBlock, contentBlock.sys.contentType.sys.id),
      )
      .filter(Boolean);
  }
}
