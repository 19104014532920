import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

export const LangContext = React.createContext()

export function LangContextProvider ({ children }) {
  const router = useRouter()
  const lang = router.locale

  return (
    <LangContext.Provider value={{ lang }}>
      {children}
    </LangContext.Provider>
  )
}

LangContextProvider.propTypes = {
  children: PropTypes.node
}

export default function useLang () {
  return useContext(LangContext)
}
