import { IImageBanner } from '../types/IImageBanner'
import { ILinkListResponse } from '../types/ILinkListResponse'
import { mapLink } from './mapLink'

export default function mapImageBanner (data: ILinkListResponse): IImageBanner {
  return {
    mediaSrc: data.imageBannerMedia.fields.file.url,
    cta: data.imageBannerCta ? mapLink(data.imageBannerCta, data.imageBannerLabel) : undefined,
    buttonVariant: data.imageBannerButtonVariant
  }
}
