import { mapLink } from 'src/features/shared/contentful/mappers/mapLink';
import {
  ICardSlider,
  ICardSliderFields,
} from 'src/features/shared/contentful/types/ICardSlider';
import { mapCardItem } from 'src/services/contentful/utils/map-card-item';

export function mapCardSlider(data: ICardSliderFields): ICardSlider {
  return {
    title: data.title,
    description: data.description,
    buttonLink: data.buttonLink
      ? mapLink(data.buttonLink, data.buttonLabel || '')
      : undefined,
    items: mapCardItem(data.items),
  };
}
