import { DEFAULT_IMAGE_SIZES } from 'src/features/shared/constants/defaultImageSizes';

const DEFAULT_IMAGE_QUALITY = 80;
const IMAGE_FORMAT = 'webp';

export default function mapImage(data, imageSizes, quality) {
  if (!data || !data.fields?.file || !data.fields?.file?.contentType) {
    return null;
  }

  const imageQuality = quality || DEFAULT_IMAGE_QUALITY;
  const IMAGE_SIZES = imageSizes || DEFAULT_IMAGE_SIZES;

  const images = IMAGE_SIZES.map(
    (size) => `${data.fields.file.url}?w=${size}&q=${imageQuality} ${size}w`,
  );
  const imagesWebp = IMAGE_SIZES.map(
    (size) =>
      `${data.fields.file.url}?w=${size}&fm=${IMAGE_FORMAT}&q=${imageQuality} ${size}w`,
  );

  return {
    alt: data.fields.title,
    src: `${data.fields.file.url}?w=${IMAGE_SIZES[0]}&q=${imageQuality}`,
    srcSet: images.join(','),
    srcSetWebp: imagesWebp.join(','),
    // Todo: Temporary solution to get original image, remove in the future
    // Ticket: https://olympusinvestment.atlassian.net/jira/software/c/projects/SC/boards/28?quickFilter=135&selectedIssue=SC-3958
    srcOriginal: data.fields.file.url,
  };
}
