import Icons from 'features/shared/components/icons';
import { LARGE_SCREEN_COLUMN_WIDTH } from '@features/layout/footer/constants/primary-columns';
import { Col } from '@features/shared/components/layout';
import { useAnimateHeight } from '@utils/hooks/use-animated-height';

import { FontColor, H5 } from 'src/features/shared/components/typography';
import { IFooterColumn } from '../../../types/IFooterColumn';
import { FooterLink } from '../../shared/footer-link';
import styles from './PrimaryFooterColumn.module.scss';
import { useEffect } from 'react';

export interface IPrimaryFooterColumnProps {
  column: IFooterColumn;
  isMobile: boolean;
  isOpen: boolean;
  onMobileClick: () => void;
}

export const PrimaryFooterColumn = ({
  column,
  isMobile,
  isOpen,
  onMobileClick,
}: IPrimaryFooterColumnProps) => {
  const { containerRef, setIsOpen, parentRef } = useAnimateHeight();

  const handleClick = () => {
    if (isMobile) {
      onMobileClick();
    }
  };

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  return (
    <Col
      columns={`sm:3 lg:${LARGE_SCREEN_COLUMN_WIDTH}`}
      className={styles.column}
    >
      <div ref={isMobile ? parentRef : undefined}>
        <div className={styles.columnTitleContainer} onClick={handleClick}>
          <H5 className={styles.columnTitle} color={FontColor.Neutral06}>
            {column.title}
          </H5>

          {isMobile && (
            <>
              {isOpen ? (
                <Icons.Minus className={styles.mobileIcon} />
              ) : (
                <Icons.Plus className={styles.mobileIcon} />
              )}
            </>
          )}
        </div>

        <div
          ref={isMobile ? containerRef : undefined}
          className={isMobile ? styles.mobileItemsWrapper : undefined}
          style={isMobile ? { height: 0 } : undefined}
        >
          {column.menuItems.map((item) =>
            item.href ? (
              <FooterLink
                href={item.href}
                label={item.label}
                isOpenInNewTab={item.isOpenInNewTab}
                key={item.id}
              />
            ) : null,
          )}

          {column.extraLinkHref && column.extraLinkLabel ? (
            <FooterLink
              href={column.extraLinkHref}
              label={column.extraLinkLabel}
              isExtraLink
            />
          ) : null}
        </div>
      </div>
    </Col>
  );
};
