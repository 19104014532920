import { mapLink } from '@features/shared/contentful/mappers/mapLink';
import { MappedImage } from 'src/features/pg-funnel/services/contentful/types';
import { DEFAULT_IMAGE_SIZES } from 'src/features/shared/constants/defaultImageSizes';
import {
  IVideoPageHeader,
  IVideoPageHeaderEntry,
} from 'src/features/shared/contentful/types/IVideoPageHeader';
import { mapVideoAsset } from 'src/services/contentful/utils/map-video-asset';
import mapImage from './map-image';

export function mapVideoPageHeader(
  data: IVideoPageHeaderEntry,
): IVideoPageHeader | null {
  if (!data) return null;
  return {
    type: 'videoPageHeader',
    title: data.fields.title,
    subtitle: data.fields.subtitle,
    backgroundVideo: mapVideoAsset(data.fields.backgroundVideo),
    mobileBackgroundVideo: mapVideoAsset(data.fields.mobileBackgroundVideo),
    primaryButtonLabel: data.fields.primaryButtonLabel,
    secondaryButtonLabel: data.fields.secondaryButtonLabel,
    primaryButton: mapLink(
      data.fields.primaryButton,
      data.fields.primaryButtonLabel,
    ),
    secondaryButton: mapLink(
      data.fields.secondaryButton,
      data.fields.secondaryButtonLabel,
    ),
    hasClubSearch: data.fields.hasClubSearch,
    stickerButtonImage: mapImage(
      data.fields.stickerButtonImage,
      DEFAULT_IMAGE_SIZES,
      100,
    ) as MappedImage,
    stickerButtonLink: mapLink(
      data.fields.stickerButtonLink,
      'Sticker Button Link',
    ),
    theme: data.fields.theme,
  };
}
