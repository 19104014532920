export default function mapMapSection (data) {
  if (!data) return null
  return {
    city: data.city,
    intro: data.intro,
    description: data.description,
    hasNegativeMargin: data.hasNegativeMargin,
    visibleClubs: data.visibleClubs ? data.visibleClubs.map((club) => club.fields.title) : null
  }
}
