import {
  takeEvery,
  put,
  call,
  select
} from 'redux-saga/effects'
import * as Actions from './actions'
import * as Selectors from './selectors'
import * as CmsService from 'services/contentful'

export default function * cmsSagas () {
  yield takeEvery(Actions.fetchMenus, onFetchMenus)
  yield takeEvery(Actions.fetchAllPageUrls, onFetchAllPageUrls)
  yield takeEvery(Actions.fetchAllClubPageUrls, onFetchAllClubPageUrls)
  yield takeEvery(Actions.fetchCheckout, onFetchCheckout)
}

function * onFetchMenus ({
  resolve,
  reject,
  lang = 'nl',
  preview
}) {
  const menus = yield select(Selectors.getMenus, { lang })
  if (Object.keys(menus).length !== 0) {
    yield put(resolve(lang, menus))
  }
  try {
    const data = yield call(CmsService.fetchMenus, lang, preview)
    yield put(resolve(lang, data))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}

function * onFetchAllPageUrls ({ resolve, reject }) {
  try {
    const pages = yield call(CmsService.fetchAllPageUrls)
    yield put(resolve(pages))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}

function * onFetchAllClubPageUrls ({ resolve, reject }) {
  try {
    const pages = yield call(CmsService.fetchAllClubPageUrls)
    yield put(resolve(pages))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}

function * onFetchCheckout ({
  resolve,
  reject,
  lang = 'nl',
  isAlternative,
  promoQueryParameter,
  preview
}) {
  const checkout = yield select(Selectors.getCheckoutByLang, { lang })
  if (checkout) {
    yield put(resolve())
  }

  try {
    const data = yield call(CmsService.fetchCheckout, lang, isAlternative, promoQueryParameter, preview)
    yield put(resolve(lang, isAlternative, promoQueryParameter, data))
  } catch (e) {
    const statusCode = e.statusCode || 500
    const message = e.message || 'service unavailable'
    yield put(reject(statusCode, message))
  }
}
