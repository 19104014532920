import { IMembershipSectionContentFields } from '@features/pg-funnel/services/contentful/types/generated/contentful'
import { IMembershipSection } from '@features/shared/contentful/types/IMembershipSection'
import mapMemberships from './map-memberships'

export default function mapMembershipSection (data: IMembershipSectionContentFields): IMembershipSection {
  return {
    title: data.title,
    description: data.description,
    cards: mapMemberships(data.memberships),
    showComparisonTable: data.showComparisonTable,
    backgroundColor: data.backgroundColor
  }
}
