import createImmerReducer from 'utils/redux/helpers/create-immer-reducer'
import * as Actions from './actions'
import { DOMAIN } from './constants'

const initialState = {
  pages: {},
  menus: {},
  checkout: {}
}

function onFetchMenusSuccess (state, { lang, data }) {
  state.menus[`${lang}`] = data
}

function onFetchCheckoutSuccess (state, { lang, data }) {
  state.checkout[`${lang}`] = data
}

export default createImmerReducer(DOMAIN, {
  [Actions.fetchMenusSuccess]: onFetchMenusSuccess,
  [Actions.fetchCheckoutSuccess]: onFetchCheckoutSuccess
}, initialState)
