import { ICheckout } from 'src/features/shared/contentful/types/ICheckout';
import { mapFaq } from './map-faq';
import mapImage from './map-image';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function mapCheckout(data: any): ICheckout {
  return {
    slug: data.fields.slug,
    title: data.fields.title,
    introTitle: data.fields.introTitle,
    introDescription: data.fields.introDescription,
    introImage: mapImage(data.fields.introImage),
    config: data.fields.config,
    usps: data.fields.usps,
    summaryPromotions: data.fields.summaryPromotions,
    introFaq: data.fields.introFaq?.map(mapFaq),
    clubFaq: data.fields.clubFaq?.map(mapFaq),
    membershipFaq: data.fields.membershipFaq?.map(mapFaq),
    detailsFaq: data.fields.detailsFaq?.map(mapFaq),
    extrasFaq: data.fields.extrasFaq?.map(mapFaq),
    summaryFaq: data.fields.summaryFaq?.map(mapFaq),
    metaDescription: data.fields.metaDescription,
  };
}
