export const ROUTES = {
  SIGN_UP: '/v1/member_signups',
  CONTRACT_DETAILS: '/v1/contract_details',
  GROUP_LESSONS: '/v1/group_lessons',
  VOUCHER_CODES: '/v1/voucher_codes',
  FUNNEL_CONFIG: '/api/website/funnel_config',
  VALID_EMAIL: '/v1/valid_email',
  SPECIALISTS: '/api/website/specialists',
  CHECKOUT: '/api/website/checkouts',
  CLUBS: '/api/website/clubs',
};
