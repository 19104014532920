import mapImageCarouselItems from 'services/contentful/utils/map-image-carousel-items'

export default function mapImageCarouselSection (data) {
  if (!data) return
  return {
    title: data.title,
    description: data.description,
    items: mapImageCarouselItems(data.items),
    hasLightbox: data.hasLightbox,
    anchorLinkTitle: data.anchorLinkTitle
  }
}
