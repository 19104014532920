import mapCheckoutUsps from 'services/contentful/utils/map-checkout-usps'

export default function mapCheckoutUspsSection (data) {
  if (!data) return

  return {
    title: data?.fields?.title,
    hideOnMobile: data?.fields?.hideOnMobile,
    insertClubUsp: data?.fields?.insertClubUsp,
    usps: mapCheckoutUsps(data?.fields?.usps)
  }
}
