import { GqlUspFields } from '@features/pg-funnel/services/contentful/types';
import { Usp } from '@features/pg-funnel/hooks/use-funnel-config/types';
import { EntryFields } from 'contentful';
import { IUspFields } from 'src/features/pg-funnel/services/contentful/types/generated/contentful';

export interface FetchedUsp {
  id?: string;
  description: EntryFields.RichText;
  tooltipTitle?: string | undefined;
  tooltipDescription?: string | undefined;
  highlight?: boolean | undefined;
  isGreyedOut?: boolean;
  isTag?: boolean;
  icon?: 'Plus' | 'Checkmark' | 'Cross' | 'Arrow';
}

export const mapUsps = (
  items: GqlUspFields[] | FetchedUsp[] | IUspFields[],
): Usp[] => {
  if (!items) {
    return [];
  }

  return items?.map((item) => {
    return {
      id: item.id,
      tooltip:
        item.tooltipTitle && item.tooltipDescription
          ? {
              title: item.tooltipTitle,
              description: item.tooltipDescription,
            }
          : undefined,
      description:
        item.description && 'json' in item.description
          ? item.description.json
          : item.description,
      highlight: item.highlight,
      icon: item.icon,
      isGreyedOut: item.isGreyedOut,
      isTag: item.isTag,
    };
  });
};
