import mapImage from 'services/contentful/utils/map-image';
import { MappedImage } from 'src/features/pg-funnel/services/contentful/types';
import { mapLink } from 'src/features/shared/contentful/mappers/mapLink';
import {
  ICardItem,
  ICardItemEntry,
} from 'src/features/shared/contentful/types/ICardItem';
import { ILink } from 'src/features/shared/contentful/types/ILink';

export function mapCardItem(data: ICardItemEntry[]): ICardItem[] {
  return data?.map((item) => ({
    id: item.sys.id,
    title: item.fields.title,
    description: item.fields.description,
    link: mapLink(item.fields.link, item.fields.title) as ILink,
    image: mapImage(item.fields?.image, [700, 570, 366, 486]) as MappedImage,
  }));
}
