import { useIntl } from '@domains/i18n';

import { FooterMenu } from '@features/layout/footer/components/shared/footer-menu/FooterMenu';

import { IMenuItem } from '../../types/IMenuItem';
import theme from 'config/theme';
import styles from './LegalMenu.module.scss';
import {
  FontColor,
  FontWeight,
  Small,
} from 'src/features/shared/components/typography';

export interface ILegalMenuProps {
  links: IMenuItem[];
}

export const LegalMenu = ({ links }: ILegalMenuProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className={styles.legalMenuContainer}>
      <FooterMenu links={links} isAlignRight isSmallFontSize>
        <Small
          className={styles.copyRight}
          fontWeight={FontWeight.Bold}
          color={FontColor.Neutral03}
        >
          {formatMessage(`footer.${theme.name}.copyRight`, {
            year: new Date().getFullYear(),
          })}
        </Small>
      </FooterMenu>
    </div>
  );
};
