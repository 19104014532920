import { IMenu } from '@features/shared/contentful/types/IMenu'
import { MenuLocation } from '../types/enums/MenuLocation'

/**
 * Filter out any menus that are not part of the shared layout of the site.
 * This is based on an enum.
 */
export function filterSiteMenus (menus: IMenu[]) {
  return menus.filter(
    menu => Object.values<string>(MenuLocation).includes(menu.location)
  )
}
